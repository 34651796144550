import "./App.css";
//import { info_tours } from "./Info_tours";
import { useEffect, useRef, useState } from "react";
import { StarIcon } from "@heroicons/react/24/solid";
import Select from "react-select";
import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import { DateRange } from "react-date-range";
import { Tours } from "./tours";
import BtnLike from "./component/BtnLike";

// este import es para el posicionamiento y desplazamiento de paginas
//import { Link, animateScroll as scroll } from "react-scroll";

import { Link } from "react-router-dom";
import es from "date-fns/locale/es";
//import Layout from "./Layout";

/* registerLocale("es", es); */

function Inicio_en() {
  const [query, setQuery] = useState("");

  const [selectedCategoria, setSelectedCategoria] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [calendar, setCalendar] = useState("");
  const [open, setOpen] = useState(false);
  const refOne = useRef(null);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  useEffect(() => {
    setCalendar(format(new Date(), "dd/MM/yyyy"));
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    /* console.log(refOne.current);
        console.log(e.target); */
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  let dirTour = "";

  const categoria = [
    /*  {
      label: "Todos",
      value: "_",
    }, */
    {
      label: "CDMX",
      value: "CDMX",
    },
    /*  {
      label: "Nacional",
      value: "nacional",
    }, */
    /* {
      label: "Internacional",
      value: "internacional",
    }, */
  ];
  var categoriaSeleccionada = "";

  const handleFavoriteUpdate = () => {
    setIsFavorite((prevIsFavorite) => !prevIsFavorite);
  };

  const handleSelectChange = (value) => {
    setSelectedCategoria(value);
    categoriaSeleccionada = value;
  };

  const handleSelect = (date) => {
    setCalendar(format(date, "dd/MM/yyyy" | es));
  };

  let id = 0;

  const [selectedValue, setSelectedValue] = useState("cultural");

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  const styles = {
    container: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    heading: {
      color: "pink",
      textAlign: "center",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: "20px",
      borderRadius: "8px",
      backgroundColor: "white",
      padding: "30px",
      boxShadow: "0px 2px 3.84px rgba(0, 0, 0, 0.25)",
    },
    radioButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    radioLabel: {
      marginLeft: "8px",
      fontSize: "17px",
      color: "#333",
    },
  };

  return (
    <center>
      <h1 className="heading">Seelect your next tour</h1>
      <div className="contenedor_busca_tour_fecha">
        <div className="centrar">
          <div className="formato_pagina">
            {Tours.filter((tour) => tour.tipo.includes(selectedValue)).map(
              (tour) => (
                <div className="contenedor_general_inicio" key={tour.id}>
                  <img
                    src={tour.imagenes[0]}
                    alt={tour.nombre_tour}
                    className="imagenes"
                  />

                  <div className="contenedor_info">
                    <div className="contenedor_formato">
                      <h3 className="titulo_nombre_tour">{tour.nombre_tour}</h3>
                      <div className="contenedor_estrella">
                        <BtnLike />
                        <StarIcon className="star_icon" />
                      </div>
                    </div>

                    <h5>{format(range[0].startDate, "dd/MM/yyyy")}</h5>
                    <h5>{format(range[0].endDate, "dd/MM/yyyy")}</h5>

                    {/*<p className="text-gray-600 mb-4">{tour.descripcion}</p>*/}

                    {/*    <h4 className="titulo_nombre_tour">{tour.destino}</h4>
                    <p className="oculto">
                      {(id = tour.id)}
                      {(dirTour = "/Detalles_tours/" + id + "#section1")}
                      {console.log(dirTour)}
                    </p>
                    <h4>{tour.fechas}</h4>
                    <Link to={dirTour}>Ver Detalles</Link> */}
                    {/*<span className="text-gray-800">{listing.precio_en_pesos}</span> */}

                    {/*  <h3>{tour.descripcion}</h3>
              <h3>{tour.fechas}</h3>
              <h2>{tour.precio_en_pesos}</h2> */}
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        <div className="contenedor_barra_busqueda">
          <Select
            className="selector_destino"
            options={categoria}
            defaultValue={{ label: "CDMX", value: "CDMX" }}
            placeholder="CDMX"
            onChange={handleSelectChange}
          />

          <input
            type="text"
            placeholder="Select your destiny/tour"
            className="barra_busca_destino"
            onChange={(e) => setQuery(e.target.value)}
          />

          <input
            value={`Del ${format(range[0].startDate, "dd/MM/yyyy")} al ${format(
              range[0].endDate,
              "dd/MM/yyyy"
            )}`}
            readOnly
            className="input_fecha"
            onClick={() => setOpen((open) => !open)}
          />

          <div ref={refOne}>
            {open && (
              <DateRange
                size="50px"
                onChange={(item) => setRange([item.selection])}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                locale={es}
                ranges={range}
                months={1}
                direction="vertical"
                className="selector_rango_fechas"
                /*  scroll={true} */
              />
            )}
          </div>
        </div>

        <div className="formato_pagina">
          {Tours.filter(
            (tour) =>
              (tour.nombre_tour.toLowerCase().includes(query) ||
                tour.destino.toLowerCase().includes(query)) &&
              (tour.categoria.includes(
                JSON.stringify(selectedCategoria.value)
              ) ||
                tour.destino.includes(
                  JSON.stringify(selectedCategoria.value)
                )) &&
              tour.fechas >= format(range[0].startDate, "dd/MM/yyyy") &&
              tour.fechas <= format(range[0].endDate, "dd/MM/yyyy")
          ).map((tour) => (
            <div className="contenedor_general" key={id}>
              <img
                src={tour.imagenes[0]}
                alt={tour.nombre_tour}
                className="imagenes"
              />

              <div className="contenedor_info">
                <div className="contenedor_formato">
                  <h3 className="titulo_nombre_tour">{tour.nombre_tour}</h3>
                  <div className="contenedor_estrella">
                    <BtnLike />
                    <StarIcon className="star_icon" />
                  </div>
                </div>

                {/*<p className="text-gray-600 mb-4">{tour.descripcion}</p>*/}

                <h4 className="titulo_nombre_tour">{tour.destino}</h4>
                <p className="oculto">
                  {(id = tour.id)}

                  {(dirTour = "/Detalles_tours/" + id + "#targetElement")}
                </p>
                <h5>Fechas de Tour</h5>
                <h6>{tour.fechas}</h6>
                <Link to={dirTour}>Ver Detalles</Link>
                {/*<span className="text-gray-800">{listing.precio_en_pesos}</span>

                {/*  <h3>{tour.descripcion}</h3>
              <h3>{tour.fechas}</h3>
              <h2>{tour.precio_en_pesos}</h2> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </center>
  );
}

export default Inicio_en;
