import React from "react";
import { useState } from "react";
import { HeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as FilledHeartIcon } from "@heroicons/react/24/solid";

const BtnLike = () => {
  const [isFavorite, setIsFavorite] = useState(false);

  const handleFavoriteUpdate = () => {
    setIsFavorite((prevIsFavorite) => !prevIsFavorite);
  };

  return (
    <button onClick={handleFavoriteUpdate} className="boton_like">
      {isFavorite ? (
        <FilledHeartIcon className="corazon_relleno" />
      ) : (
        <HeartIcon className="corazon_vacio" />
      )}
    </button>
  );
};

export default BtnLike;
