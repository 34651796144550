export const Tours = [
  {
    id: 1,
    nombre_tour: "Vuelo en Globo con Tour en Teotihuacán",
    categoria: '"Nacional""_"',
    fechas: ["02/03/2024"],
    destino: '"CDMX"',
    descripcion:
      "Es una experiencia única, te recogeremos en tu alojamiento para llevarte al Globopuerto donde podrás disfrutar de un Coffee Break, mientras observas el armado e inflado del globo en el que partirás.\n\nEl vuelo tendrá una duración de 45 a 60 minutos, donde podrás contemplar el amanecer, la majestuosa ciudad de los dioses y diferentes globos que te acompañarán durante tu experiencia.\n\nCuando estés de vuelta en tierra firme, disfrutarás de un brindis con vino espumoso y un desayuno en un restaurante local para recargar energías y continuar nuestro día con un guía certificado durante una caminata de 1 hora, a hora y media en la Zona Arqueológica de Teotihuacán.",
    requisitos: "_",
    incluye:
      "-Transporte redondo desde su alojamiento (pregunte por nuestras zonas de pick up)\n\n-Guía certificado\n\n-Bebidas de cortesía\n\n-Desayuno\n\n-Entradas y tour guiado en Teotihuacán",
    precio_en_pesos: "$3,900.00 MXN",
    imagenes: [
      "/fotos/teo_con_globo/tg_1.jpg",
      "/fotos/teo_con_globo/tg_2.jpg",
      "/fotos/teo_con_globo/tg_3.jpg",
      "/fotos/teo_con_globo/tg_4.jpg",
    ],
    tipo: "Cultural",
  },
  {
    id: 2,
    nombre_tour: "Vuelo en Globo y Visita a Teotihuacán",
    categoria: '"Nacional""_"',
    fechas: ["02/03/2024"],
    destino: '"CDMX"',
    descripcion:
      "Es una experiencia única, te recogeremos en tu alojamiento para llevarte al Globopuerto donde podrás disfrutar de un Coffee Break, mientras observas el armado e inflado del globo en el que partirás.\n\nEl vuelo tendrá una duración de 45 a 60 minutos, donde podrás contemplar el amanecer, la majestuosa ciudad de los dioses y diferentes globos que te acompañarán durante tu experiencia.\n\nCuando estés de vuelta en tierra firme, disfrutarás de un brindis con vino espumoso y un desayuno en un restaurante local para recargar energías y continuar con la visita de 2 horas en la Zona Arqueológica de Teotihuacán.",
    requisitos: "_",
    incluye:
      "-Transporte redondo desde su alojamiento (pregunte por nuestras zonas de pick up)\n\n-Guía certificado\n\n-Bebidas de cortesía\n\nDesayuno\n\n-Entrada a la zona arqueológica de Teotihuacán",
    precio_en_pesos: "$3,500.00 MXN",
    imagenes: [
      "/fotos/teo_con_globo/tg_7.jpg",
      "/fotos/teo_con_globo/tg_2.jpg",
      "/fotos/teo_con_globo/tg_3.jpg",
      "/fotos/teo_con_globo/tg_4.jpg",
    ],
    tipo: "Cultural",
  },

  {
    id: 3,
    nombre_tour: "TEOTIHUACAN Tour Guiado Privado",
    categoria: '"nacional""_"',
    fechas: ["30/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "Esta visita se hace a mano de uno de nuestros guías quien te recogerá desde tu alojamiento,(pregunta por nuestras zonas de pick ups)\n\nTe adentrarás a la historia de este gran lugar de más de dos mil años de antigüedad visitaremos palacios de la gente de élite en Teotihuacán para posterior visitar plaza y pirámide de la luna, en plaza de la luna escucharemos la acústica del lugar y nos dirigiremos hacia la calzada de los muertos donde podremos observar pintura mural para terminar frente a pirámide del sol",
    requisitos: "_",
    incluye:
      "-Transporte privado redondo(pregunta por nuestras zonas de pick up)\n\n-Guía certificado\n\n-Bebidas de cortesía\n\n-Entrada y tour guiado en la zona arqueológica",
    precio_en_pesos: "$2,200 MXN",
    imagenes: [
      "/fotos/teo/t_basil_1.jpg",
      "/fotos/teo/t_2.jpg",
      "/fotos/teo/t_3.jpg",
      "/fotos/teo/t_4.jpg",
    ],
    tipo: "Cultural",
  },

  {
    id: 4,
    nombre_tour: "XOCHIMILCO/ COYOACAN / FRIDA KAHLO",
    categoria: '"nacional""_"',
    fechas: [["28/01/2024"], ["03/02/2024"]],
    destino: '"CDMX"',
    descripcion:
      "¡Vive una experiencia inolvidable, llena de folklore, cultura, belleza y magia; estos son los ingredientes que conforman este maravilloso recorrido por los atractivos principales de la Ciudad de México!\n\n Nuestro recorrido comienza con rumbo a los canales de Xochimilco, patrimonio cultural de la humanidad.\n\n Disfruta de un relajante y alegre recorrido por los canales de Xochimilco a bordo un colorido bote de madera llamado Trajinera.\n\n Podrás comprar bebidas locales bocadillos regionales en uno de los barcos restaurante que recorren los canales.\n\n Disfruta con la música regional del mariachi, que amenizan y encienden el ambiente del lugar.\n\n Continuaremos el recorrido hacia el barrio de Coyoacán, lugar lleno de cultura y tradición, favorito de grandes artistas y escritores mexicanos; camina por sus calles empedradas bordeadas de árboles, admira las fachadas de estuco y azulejos de sus hermosas casas coloniales y empápate con su historia.\n\n Posteriormente, nos dirigiremos al Museo Frida Kahlo, que es la antigua casa de la famosa pintora mexicana.\n\n Podrás aprender sobre la vida de esta figura icónica y su famoso esposo, Diego Rivera. así mismo, podrás apreciar el pintoresco jardín y pasear por él.",
    incluye:
      "-Transporte redondo (pregunte por nuestras zonas de pick up)\n\n-Paseo en trajinera de 1 hora\n\n2-Bebidas de cortesía\n\n-Entradas a los diferentes puntos de visitas\n\n-Snack de cortesía\n\n-Inicio del Tour: 8:00 hrs Aprox.",
    requisitos:
      "*Este tour se debe de reservar con 2 semanas de antelación\n\n*Mínimo 2 personas",
    precio_en_pesos: "$2,900.00 MXN",
    imagenes: [
      "/fotos/xochi_coyo_frida/x1.jpg",
      "/fotos/xochi_coyo_frida/x2.jpg",
      "/fotos/xochi_coyo_frida/x3.jpg",
      "/fotos/xochi_coyo_frida/x4.jpg",
    ],
    tipo: "Cultural",
  },

  {
    id: 5,
    nombre_tour: "TOUR FRIDA KAHLO Y DIEGO RIVERA",
    categoria: '"nacional""_"',
    fechas: ["02/03/2024"],
    destino: '"CDMX"',
    descripcion:
      "Embárcate en un viaje de 4 horas para explorar las vibrantes obras de vida de dos legendarios artistas mexicanos: Frida Kahlo y Diego Rivera.\n\nEn este recorrido privado, obtendrá una mirada íntima a sus mejores obras maestras mientras visita el Museo Casa Estudio Diego Rivera y Frida Kahlo, el Museo Anahuacalli y las famosas paredes azules que albergan algunas de las obras más conocidas de Kahlo en su antigua morada: Casa Azul.",
    requisitos:
      "*Este tour se debe de reservar con 2 semanas de antelación\n\n*Mínimo 2 personas",
    incluye:
      "-Transporte redondo (pregunte por nuestras zonas de pick up)\n\n-Guía certificado\n\n-Entradas a los diferentes puntos de visitas\n\n-Snack de cortesía\n\n-2 Bebidas de cortesí",
    precio_en_pesos: "$2,500.00 MXN",
    imagenes: [
      "/fotos/frida_diego/f_1.jpg",
      "/fotos/frida_diego/f_2.jpg",
      "/fotos/frida_diego/f_3.jpg",
      "/fotos/frida_diego/f_4.jpg",
    ],
    tipo: "Cultural",
  },
  {
    id: 6,
    nombre_tour: "KAYAK AL AMANECER",
    categoria: '"nacional""_"',
    fechas: ["31/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "Este recorrido en Kayak Xochimilco es una experiencia inmersiva y única que te llevará a una nueva aventura por el surrealista pueblo de Xochimilco.\n\nDonde la flora y la fauna endémicas son testigos de un fascinante recorrido que deleita los sentidos en un ambiente completamente natural.\n\nVisitaremos las chinampas principales del lugar, como la Isla de las Muñecas, la Laguna de Tlilac, y también conoceremos el Axolotl o Ajolote, una salamandra endémica en peligro de extinción considerada como deidad por los Aztecas debido a su capacidad de regeneración celular.\n\nDurante todo el recorrido observaremos aves locales y migratorias que relajan el alma, además al terminar disfrutaremos de un desayuno preparado con ingredientes locales",
    requisitos: "_",
    incluye:
      "Transporte privado redondo\n\nGuía certificado \n\n1 Bebida de cortesía\n\nDesayuno\n\nEntradas a los diferentes puntos de visitas",
    precio_en_pesos: "$1,950.00 MXN",
    imagenes: [
      "/fotos/kayak/k_1.jpg",
      "/fotos/kayak/k_2.jpg",
      "/fotos/kayak/k_3.jpg",
      "/fotos/kayak/k_4.jpg",
    ],
    tipo: "De Aventura",
  },
  {
    id: 7,
    nombre_tour: "GRUTAS DE TOLANTONGO PRIVADO",
    categoria: '"nacional""_"',
    fechas: ["31/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "Únete a nosotros en una emocionante aventura en las Grutas de Tolantongo con nuestro tour especial.\n\n Nos encargaremos de tu transporte desde y hacia tu ubicación, garantizando comodidad y seguridad en todo momento.\n\n Durante el trayecto, nuestro guía compartirá contigo los secretos de la auténtica barbacoa, plato tradicional que deleitará tu paladar con su exquisito sabor.\n\n Además, tendrás la oportunidad de probar el pulque, una bebida típica que complementará tu experiencia gastronómica.\n\n Al llegar a las Grutas, te sorprenderás con su belleza natural mientras exploras sus impresionantes cuevas y piscinas termales.\n\n Para refrescarte, te obsequiaremos con dos bebidas de cortesía, para que disfrutes al máximo de este día lleno de aventura y descubrimientos.\n\n Puedes complementar tu experiencia con uno de los ziplines más grandes de México",
    requisitos: "*Tiempo de reserva mínimo, 1 semana\n*Mínimo de personas 2",
    incluye:
      "-Transporte redondo  (pregunte por nuestras zonas de pick up)\n\n-Guía certificado\n\n-2 Bebidas de cortesía\n\n-Degustación del pulque\n\n-Entradas a los diferentes puntos de visitas",
    precio_en_pesos: "$2,800.00 MXN",
    imagenes: [
      "/fotos/tolantongo/tola_1.jpg",
      "/fotos/tolantongo/tola_2.jpg",
      "/fotos/tolantongo/tola_3.jpg",
      "/fotos/tolantongo/tola_4.jpg",
    ],
    tipo: "De Aventura",
  },

  {
    id: 8,
    nombre_tour: "CENTRO HISTORICO Walking Tour Privado",
    categoria: '"nacional""_"',
    fechas: ["31/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "Visitaremos la Pirámide del Templo Mayor en Tenochtitlán, una ciudad prehispánica.\n\nVisitaremos la Catedral Metropolitana y contemplaremos su estilo arquitectónico ecléctico, el recorrido continúa en la calle Francisco I. Madero donde apreciaremos la arquitectura colonial y moderna como, la Casa de los Azulejos, la Torre Latinoamericana, el Museo Nacional de Arte, el Palacio de Minería y la Oficina de Correos.\n\nPor último, apreciaremos el Palacio de Bellas Artes, su arquitectura e historia.\n\nDentro de todos estos monumentos hay grandes historias, anécdotas y leyendas interesantes que contar que disfrutaremos mucho.\n\nViajaremos en el tiempo por esta hermosa ciudad, desde la época prehispánica hasta la época colonial, ¡y finalmente por la Ciudad Moderna",
    requisitos: "_",
    incluye:
      "-Guía certificado\n\n-Sombrilla para el sol\n\n-2 Bebidas de Cortesía",
    precio_en_pesos: "$1,100 MXN",
    imagenes: [
      "/fotos/centro_historico/ch_1.jpg",
      "/fotos/centro_historico/ch_2.jpg",
      "/fotos/centro_historico/ch_3.jpg",
      "/fotos/centro_historico/ch_4.jpg",
    ],
    tipo: "Cultural",
  },
  {
    id: 9,
    nombre_tour: "CASTILLO DE CHAPULTEPEC Y MUSEO DE ANTROPOLOGIA TOUR PRIVADO",
    categoria: '"nacional""_"',
    fechas: ["31/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "Realice una excursión a primera hora de la mañana para descubrir la fascinante historia de México desde los días de los aztecas hasta el presente,conozca el colonialismo español y la independencia de México mientras contempla los aposentos reales y las importantes obras de arte del castillo de Chapultepec.\n\n Continúe hacia el cercano Museo Nacional de Antropología para ver los artefactos más importantes, como las cabezas talladas de los Olmecas, la tumba de Pakal y la Piedra del Sol.",
    requisitos: "_",
    incluye:
      "-Guía certificado\n-Sombrillas para el sol\n-1 Bebida de Cortesía\n-1 Snack de Cortesía\n-Entradas y tour guiado a ambos museos",
    precio_en_pesos: "$1,100 MXN",
    imagenes: [
      "/fotos/castillo_chapultepec/castillo_chap_1.jpg",
      "/fotos/castillo_chapultepec/castillo_chap_2.jpg",
      "/fotos/castillo_chapultepec/castillo_chap_3.jpg",
      "/fotos/castillo_chapultepec/castillo_chap_4.jpg",
    ],
    tipo: "Cultural",
  },

  //ACTUALIZAR IDS
  /* {
    id: 6,
    nombre_tour: "TLATELOLCO + TEOTIHUACAN + BASILICA",
    categoria: '"nacional""_"',
    fechas: ["30/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "¡Descubre las raíces culturales de la Ciudad de México, este increíble recorrido te permitirá conoce la historia y la belleza de la ciudad más grande de todo México! Tu recorrido inicia con una visita panorámica en Tlatelolco, la “Plaza de las Tres Culturas”.\n\n\n\nLlamada así porque los conjuntos arquitectónicos ubicados en su alrededor provienen de tres etapas históricas diferentes.\n\n\n\nPosteriormente llegarás a un taller de artesanías, para conocer el proceso de elaboración del pulque; apreciarás los telares y el ancestral trabajo con la obsidiana y la plata.\n\n\n\nSeguiremos hacia Teotihuacán para viajar al pasado con la ayuda del guía experto.\n\n\n\nSe hará una parada para degustar los alimentos (comida no incluida) y tomar un descanso.\n\n\n\n De vuelta a la Ciudad de México, visitará al segundo centro religioso mas visitado del mundo: la Basílica de Guadalupe, Podrás, además, apreciar la belleza del manto de la Virgen María.\n\n\n\n¡Una experiencia inigualable con momento memorables para atesorar! Inicio del Tour: 09:00 hrs Aprox.",
    requisitos: "",
    incluye:
      "Transporte privado redondo\n\nGuía certificado\n\n1 Bebida de cortesía\n\nEntradas a los diferentes puntos de visitas",
    precio_en_pesos: "$1,900.00",
    imagenes: [
      "/fotos/teo_con_globo/teo_basil/t_basil_5.jpg",
      "/fotos/teo_con_globo/teo_basil/t_basil_2.jpg",
      "/fotos/teo_con_globo/teo_basil/t_basil_3.jpg",
      "/fotos/teo_con_globo/teo_basil/t_basil_4.jpg",
    ],
    tipo: "Cultural Religioso",
  }, */
  /*  {
    id: 7,
    nombre_tour: "CENTRO HISTORICO Walking Tour Exterior",
    categoria: '"nacional""_"',
    fechas: ["30/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "Visitaremos la Pirámide del Templo Mayor en Tenochtitlán, una ciudad prehispánica.\n\n\n\nVisitaremos la Catedral Metropolitana y contemplaremos su estilo arquitectónico ecléctico, el recorrido continúa en la calle Francisco I.\n\n\n\nMadero donde apreciaremos la arquitectura colonial y moderna como el, la Casa de los Azulejos, la Torre Latinoamericana, el Museo Nacional de Arte y la Oficina de Correos.\n\n\n\nPor último, visitaremos el Palacio de Bellas Artes, apreciaremos su arquitectura y pinturas del muralismo mexicano, pintadas por artistas como Diego Rivera, David Alfaro Siqueiros, Rufino Tamayo y otros. Dentro de todos estos monumentos hay grandes historias, anécdotas y leyendas interesantes que contar que disfrutaremos mucho.\n\n\n\nViajaremos en el tiempo por esta hermosa ciudad, desde la época prehispánica hasta la época colonial, ¡y finalmente por la Ciudad Moderna!\n\n\n\n",
    requisitos: "",
    incluye: "Guía certificado\n\nSombrillas para el sol\n\n1 Bebida de Cortesía",
    precio_en_pesos: "$650.00",
    imagenes: [
      "/fotos/centro_historico/ch_1.jpg",
      "/fotos/centro_historico/ch_2.jpg",
      "/fotos/centro_historico/ch_3.jpg",
      "/fotos/centro_historico/ch_4.jpg",
    ],
    tipo: "Cultural",
  },
  {
    id: 8,
    nombre_tour: "CENTRO HISTORICO Walking Tour Interior",
    categoria: '"nacional""_"',
    fechas: ["02/02/2024"],
    destino: '"CDMX"',
    descripcion:
      "Visitaremos la Pirámide del Templo Mayor en Tenochtitlán, una ciudad prehispánica.\n\n\n\nVisitaremos el interior de la Catedral Metropolitana y contemplaremos su estilo arquitectónico ecléctico, el recorrido continúa en la calle Francisco I.\n\n\n\nMadero donde apreciaremos la arquitectura colonial y moderna como el, la Casa de los Azulejos, la Torre Latinoamericana, el Museo Nacional de Arte, el Palacio de Minería y la Oficina de Correos esta ultima podremos pasar a su interior a admirar su maravillosa arquitectura e historia.\n\n\n\nPor último, visitaremos el interior del Palacio de Bellas Artes, apreciaremos su arquitectura y pinturas del muralismo mexicano, pintadas por artistas como Diego Rivera, David Alfaro Siqueiros, Rufino Tamayo y otros.",
    requisitos: "",
    incluye:
      "Transporte privado redondo\n\nGuía certificado\n\n1 Bebida de Cortesía\n\nSombrillas para el sol\n\nEntradas a los diferentes puntos de visitas",
    precio_en_pesos: "$850.00",
    imagenes: [
      "/fotos/centro_historico/ch_1.jpg",
      "/fotos/centro_historico/ch_2.jpg",
      "/fotos/centro_historico/ch_3.jpg",
      "/fotos/centro_historico/ch_4.jpg",
    ],
    tipo: "Cultural Museos",
  },
  {
    id: 9,
    nombre_tour: "MUSEOS Walking Tour",
    categoria: '"nacional""_"',
    fechas: ["16/08/2023"],
    destino: '"CDMX"',
    descripcion:
      "Realice una excursión a primera hora de la mañana para descubrir la fascinante historia de México desde los días de los aztecas hasta el presente.\n\n\n\nConozca el colonialismo español y la independencia de México mientras contempla los aposentos reales y las importantes obras de arte del castillo de Chapultepec.\n\n\n\nContinúe hacia el cercano Museo Nacional de Antropología para ver los artefactos más importantes, como las cabezas talladas de los Olmecas, la tumba de Pakal y la Piedra del Sol.",
    requisitos: "",
    incluye:
      "Guía certificado\n\n1 Bebidas de Cortesía\n\nEntradas a los diferentes puntos de visitas",
    precio_en_pesos: "$850.00",
    imagenes: [
      "/fotos/centro_historico/ch_1.jpg",
      "/fotos/centro_historico/ch_2.jpg",
      "/fotos/centro_historico/ch_3.jpg",
      "/fotos/centro_historico/ch_4.jpg",
    ],
    tipo: "Cultural",
  },
  {
    id: 10,
    nombre_tour: "Walking Tour Exterior",
    categoria: '"nacional""_"',
    fechas: ["17/08/2023"],
    destino: '"CDMX"',
    descripcion:
      "Realice una excursión a primera hora de la mañana para descubrir la fascinante historia de México desde los días de los aztecas hasta el presente.\n\n\n\nConozca el colonialismo español y la independencia de México mientras contempla los aposentos reales y las importantes obras de arte del castillo de Chapultepec.\n\n\n\nContinúe hacia el cercano Museo Nacional de Antropología para ver los artefactos más importantes, como las cabezas talladas de los Olmecas, la tumba de Pakal y la Piedra del Sol.",
    requisitos: "",
    incluye:
      "Guía certificado\n\n1 Bebidas de Cortesía\n\nEntradas a los diferentes puntos de visitas",
    precio_en_pesos: "$860.00",
    imagenes: [
      "/fotos/centro_historico/chi_2.jpg",
      "/fotos/centro_historico/chi_1.jpg",
      "/fotos/centro_historico/chi_3.jpg",
      "/fotos/centro_historico/chi_4.jpg",
    ],
    tipo: "Cultural",
  },
 */
  /* {
    id: 11,
    nombre_tour: "Barrancas del Cobre",
    categoria: '"nacional""_"',
    fechas: ["21/11/2023", "12/12/2023", "14/12/2023", "19/12/2023"],
    destino:
      " Chihuahua, Creel, Divisadero, Cascada de Basaseachi , Parque de Aventura, Lago de Arareko, Valle de los Hongos y El Fuerte.",
    descripcion:
      "Visitando Chihuahua, Creel, Divisadero, Cascada de Basaseachi , Parque de Aventura, Lago de Arareko, Valle de los Hongos y El Fuerte.",
    incluye:
      "\n\n• Vuelos CDMX-Chihuahua//Los Mochis-CDMX \n\n• Traslados aeropuerto/estación tren - hoteles - aeropuerto  \n\n• 4 noches de hospedaje 4*  \n\n• 4 desayunos americanos y/o buffet  \n\n• City tour en Chihuahua  \n\n• Tren Chepe Express clase turista (Creel-El Fuerte) \n\n• Ticket de Teleférico en Parque de Aventura \n\n• Acceso al Lago de Arareko y Valle de los Hongos \n\n• Accesos incluidos (indicados en itinerario) \n\n• Coordinador de grupo \n\n• Impuestos",
    imagenes: [
      "/fotos/barrancas_cobre/barrancas_cobre_2.jpg",
      "/fotos/barrancas_cobre/barrancas_cobre_1.jpg",
      "/fotos/barrancas_cobre/barrancas_cobre_3.jpg",
      "/fotos/barrancas_cobre/barrancas_cobre_4.jpg",
    ],
    tipo: "Cultural Explora México",
  },
  {
    id: 12,
    nombre_tour: "Explorando el Norte de Mexico",
    categoria: '"nacional""_"',
    fechas: ["07/12/2023"],
    destino:
      "Monterrey, Arteaga, Cuatro Ciénegas, Poza Azul, Dunas de Yeso, Río Mezquite, Saltillo, Parras de la Fuente, Monterreal y Santiago",
    descripcion:
      "Visitando: Monterrey, Arteaga, Cuatro Ciénegas, Poza Azul, Dunas de Yeso, Río Mezquite, Saltillo, Parras de la Fuente, Monterreal y Santiago.",
    incluye:
      "• Vuelos CDMX-Monterrey-CDMX\n\n • Traslados aeropuerto-hoteles-aeropuerto\n\n • Traslados indicados\n\n • 4 noches de hospedaje 4 *\n\n • 4 desayunos americanos y/o buffet\n\n • Acceso a Río Mezquite\n\n • Velada Astronómica con expositores incluidos\n\n • Visita y degustación en Casa Madero\n\n • Visita al Museo del Desierto\n\n • Acceso a sitios de interés (indicados en itinerario)\n\n • Coordinador de grupo\n\n • Impuestos",
    imagenes: [
      "/fotos/norte_mexico/norte_mex_1.jpg",
      "/fotos/norte_mexico/norte_mex_2.jpg",
      "/fotos/norte_mexico/norte_mex_3.jpg",
      "/fotos/norte_mexico/norte_mex_4.jpg",
    ],
    tipo: "Cultural Explora México",
  },
  {
    id: 13,
    nombre_tour: "Descubriendo California",
    categoria: '"nacional""_"',
    fechas: ["29/11/2023"],
    destino:
      "Tijuana, Rosarito, Ensenada, Valle de Guadalupe, La Bufadora, Cataviña, Guerrero Negro, Laguna Ojo de Liebre, San Ignacio, Mulege, Santa Rosalía,    Loreto, La Paz, Puerto Balandra, El Tecolote, Todos Santos, Los Cabos.",
    descripcion:
      "Visitando: Tijuana, Rosarito, Ensenada, Valle de Guadalupe, La Bufadora, Cataviña, Guerrero Negro, Laguna Ojo de Liebre, San Ignacio, Mulege, Santa Rosalía,    Loreto, La Paz, Puerto Balandra, El Tecolote, Todos Santos, Los Cabos.",
    incluye:
      "\n\n • Vuelos CDMX -Tijuana//Los Cabos- CDMX\n\n • 10 noches de hospedaje 4*\n\n • Circuito terrestre Mexicali-Los Cabos\n\n • Traslados indicados\n\n • Acceso a L.A.\n\n\n\nCETTO\n\n • Acceso y degustación a Monte Xanic\n\n • Avistamiento de ballena en San Ignacio (en temporada)\n\n • Visita al Museo del Vino\n\n • Accesos y visitas indicadas en itinerario\n\n • Paseo en lancha al Arco\n\n • Coordinador de grupo\n\n • Impuestos",
    imagenes: [
      "/fotos/decubriendo_california/descubriendo_california_1.jpg",
      "/fotos/decubriendo_california/descubriendo_california_2.jpg",
      "/fotos/decubriendo_california/descubriendo_california_3.jpg",
      "/fotos/decubriendo_california/descubriendo_california_4.jpg",
    ],
    tipo: "Cultural Explora México",
  },
  {
    id: 14,
    nombre_tour: "Maravillas de Chihuahua y California",
    categoria: '"nacional""_"',
    fechas: ["21/12/2023"],
    destino:
      "Chihuahua, Creel, Valle de los Hongos, Lago de Arareko, Parque Aventura, Divisadero, El Fuerte, Mazatlán, La Paz, Puerto Balandra, El Tecolote, Todos Santos y Los Cabos.",
    descripcion:
      "Visitando: Chihuahua, Creel, Valle de los Hongos, Lago de Arareko, Parque Aventura, Divisadero, El Fuerte, Mazatlán, La Paz, Puerto Balandra, El Tecolote, Todos Santos y Los Cabos.",
    incluye:
      "\n\n• Vuelo CDMX-Chihuahua//Los Cabos-CDMX \n\n• Traslados indicados\n\n• 7 noches de hospedaje 4*\n\n• 1 noche en cabina privada Mazatlán o Topolobampo-La Paz\n\n• 8 desayunos buffet y/o americanos\n\n• Acceso a museos (Casa Chihuahua y Museo de la Revolución)\n\n• Ticket de Tren Chepe Express Creel-El Fuerte, clase turista\n\n• Ticket de Teleférico (Barrancas del Cobre)\n\n• Acceso a Parques Nacionales\n\n• Ticket Baja Ferries Mazatlán-La Paz (clase salón)\n\n• Accesos y visitas indicadas en itinerario\n\n• Tour en lancha en Cabo San Lucas\n\n• Paseo en lancha de avistamiento de la Ballena (en temporada)\n\n• Coordinador de grupo\n\n• Impuestos",
    imagenes: [
      "/fotos/maravillas_chi_y_cali/maravilas_chi_cali_1.jpg",
      "/fotos/maravillas_chi_y_cali/maravilas_chi_cali_2.jpg",
      "/fotos/maravillas_chi_y_cali/maravilas_chi_cali_3.jpg",
      "/fotos/maravillas_chi_y_cali/maravilas_chi_cali_4.jpg",
    ],
    tipo: "Cultural Explora México",
  },
  {
    id: 15,
    nombre_tour: "Barrancas de Lux",
    categoria: '"nacional""_"',
    fechas: ["28/08/2023"],
    destino:
      "El Fuerte, Cerocahui, Cerro del Gallego, Divisadero, Parque de Aventura, Creel, Valle de los Hongos, Lago de Arareko, Cascada de Basaseachi, Cd. Cuauhtémoc y Chihuahua.",
    descripcion:
      "Visitando: El Fuerte, Cerocahui, Cerro del Gallego, Divisadero, Parque de Aventura, Creel, Valle de los Hongos, Lago de Arareko, Cascada de Basaseachi, Cd. Cuauhtémoc y Chihuahua.",
    incluye:
      "\n\n • Vuelos CDMX- Los Mochis//Chihuahua -CDMX \n\n • Traslados aeropuerto/estación tren-hoteles-aeropuerto\n\n • 5 noches de hospedaje 4*\n\n • 5 desayunos americanos y/o buffet\n\n • City tour en Chihuahua (accesos incluidos)\n\n • Tren Chepe Primera (El Fuerte-Bahuichivo)\n\n • Tour al Cerro del Gallego   Acceso a Lago de Arareko y Valle de los Hongos\n\n •  Coordinador de grupo \n\n • Impuestos",
    imagenes: [
      "/fotos/barrancas_dlux/barrancas_cobre_1.jpg",
      "/fotos/barrancas_dlux/barrancas_cobre_2.jpg",
      "/fotos/barrancas_dlux/barrancas_cobre_3.jpg",
      "/fotos/barrancas_dlux/barrancas_cobre_4.jpg",
    ],
    tipo: "Cultural De Aventura Explora México",
  },
  {
    id: 16,
    nombre_tour: "Encantos de Sonora",
    categoria: '"nacional""_"',
    fechas: ["28/08/2023"],
    destino:
      "Hermosillo, San Carlos, Guaymas, Bahía de Kino, Puerto Peñasco y Biosfera del Pinacate.",
    descripcion:
      "Visitando: Hermosillo, San Carlos, Guaymas, Bahía de Kino, Puerto Peñasco y Biosfera del Pinacate.",
    incluye:
      "\n\n • Vuelos CDMX-Hermosillo-CDMX\n\n • Traslados aeropuerto-hoteles-aeropuerto\n\n • 5 noches de hospedaje 4*\n\n • 5 desayunos americanos y/o buffet \n\n • Tour a Isla Tiburón\n\n • Paseo en yate a San Carlos (1 hora)\n\n • Visitas indicadas en itinerario\n\n • Coordinador de grupo\n\n • Impuestos",
    imagenes: [
      "/fotos/encantos_sonora/Sonora_1.jpeg",
      "/fotos/encantos_sonora/Sonora_2.jpeg",
      "/fotos/encantos_sonora/Sonora_3.jpeg",
      "/fotos/encantos_sonora/Sonora_4.jpeg",
    ],
    tipo: "Cultural Explora México",
  },
  {
    id: 17,
    nombre_tour: "Santuario Ballena Gris",
    categoria: '"nacional""_"',
    fechas: ["07/03/2023"],
    destino:
      "La Paz, Playa del Pichilingue, Bahía de La Paz, Los Cabos, Puerto Balandra, Santuario de la Ballena Gris, Playa El Tecolote y Pueblo mágico Todos Santos.",
    descripcion:
      "Visitando: La Paz, Playa del Pichilingue, Bahía de La Paz, Los Cabos, Puerto Balandra, Santuario de la Ballena Gris, Playa El Tecolote y Pueblo mágico Todos Santos.",
    incluye:
      "\n\n • Vuelo CDMX-La Paz//Los Cabos-CDMX\n\n • 3 noches de hospedaje 4*\n\n • 3 desayunos americanos\n\n • Paseo en lancha de avistamiento a la ballena\n\n • Acceso a sitios indicados en itinerario\n\n • Paseo en lancha al Arco\n\n • Traslados indicados en Itinerario\n\n • Coordinador de grupo\n\n • Impuestos",
    imagenes: [
      "/fotos/santuario_ballena/santuario_ballena_gris_1.jpg",
      "/fotos/santuario_ballena/santuario_ballena_gris_2.jpg",
      "/fotos/santuario_ballena/santuario_ballena_gris_3.jpg",
      "/fotos/santuario_ballena/santuario_ballena_gris_4.jpg",
    ],
    tipo: "Cultural De Temporada Explora México",
  },
  {
    id: 18,
    nombre_tour: "Ensenada-Valle de Guadalupe",
    categoria: '"nacional""_"',
    fechas: ["24/10/2023"],
    destino:
      "Tijuana, Rosarito, La Bufadora, Ensenada, La Rumorosa, Valle de Guadalupe, Vinícolas L.A. CETTO y Monte Xanic, Tecate.",
    descripcion:
      "Visitando: Tijuana, Rosarito, La Bufadora, Ensenada, La Rumorosa, Valle de Guadalupe, Vinícolas L.A. CETTO y Monte Xanic, Tecate.",
    incluye:
      "\n\n • Vuelos México-Tijuana-México\n\n • 3 noches de hospedaje 4*\n\n • 3 desayunos americanos y/o buffet\n\n • Visita a Vinícolas y Museo del Vino\n\n • Degustación de vinos en Monte Xanic**\n\n • Visita a LA CETTO\n\n • Visitas indicadas en itinerario\n\n • Coordinador de grupo\n\n • Impuestos",
    imagenes: [
      "/fotos/ensenada_valle_gpe/ensenada_1.jpg",
      "/fotos/ensenada_valle_gpe/ensenada_2.jpg",
      "/fotos/ensenada_valle_gpe/ensenada_3.jpg",
      "/fotos/ensenada_valle_gpe/ensenada_4.jpg",
    ],
    tipo: "Cultural Explora México",
  },
  {
    id: 19,
    nombre_tour: "Tesoros del Mar de Cortés",
    categoria: '"nacional""_"',
    fechas: ["28/08/2023"],
    destino:
      "La Paz, Puerto Balandra, Isla del Espíritu Santo, Loreto, San José del Cabo, Cabo San Lucas y Todos Santos.",
    descripcion:
      "Visitando: La Paz, Puerto Balandra, Isla del Espíritu Santo, Loreto, San José del Cabo, Cabo San Lucas y Todos Santos.",
    incluye:
      "\n\n • Vuelos CDMX- La Paz // Los Cabos -CDMX\n\n • Traslados aeropuerto-hoteles-aeropuerto\n\n • 4 noches de hospedaje 4*\n\n • 4 desayunos americanos y/o buffet\n\n • Tour en lancha al Arco\n\n • Isla del Espíritu Santo (picnic incluido)\n\n • Visitas indicadas en itinerario\n\n • Coordinador de grupo\n\n • Impuestos",
    imagenes: [
      "/fotos/tesoros_mar_cortes/tesoros_mar_cortes_1.jpg",
      "/fotos/tesoros_mar_cortes/tesoros_mar_cortes_2.jpg",
      "/fotos/tesoros_mar_cortes/tesoros_mar_cortes_3.jpg",
      "/fotos/tesoros_mar_cortes/tesoros_mar_cortes_4.jpg",
    ],
    tipo: "Cultural Explora México",
  },
  {
    id: 20,
    nombre_tour: "Biolumniniscencia y Playas de Oaxaca",
    categoria: '"nacional""_"',
    fechas: ["16/11/2023"],
    destino:
      "Puerto Escondido, Playa de Puerto Angelito, Laguna de la Bioluminiscencia, Zicatela, Puerto Ángel, Mazunte, Zipolite y Huatulco.",
    descripcion:
      "Visitando: Puerto Escondido, Playa de Puerto Angelito, Laguna de la Bioluminiscencia, Zicatela, Puerto Ángel, Mazunte, Zipolite y Huatulco.",
    incluye:
      "\n\n • Vuelos CDMX-Puerto Escondido//Huatulco- CDMX\n\n • Traslados aeropuerto-hoteles-aeropuerto\n\n • 2 noches de hospedaje alojamiento Puerto Escondido con desayunos\n\n • 2 noches de hospedaje alojamiento en Huatulco Todo Incluido\n\n • Tour a Laguna Bioluminiscente\n\n • Tour a Puerto Ángel\n\n • Coordinador de grupo\n\n • Impuestos",
    imagenes: [
      "/fotos/biolumin_playas_oax/playas_oaxaca_1.jpg",
      "/fotos/biolumin_playas_oax/playas_oaxaca_2.jpg",
      "/fotos/biolumin_playas_oax/playas_oaxaca_3.jpg",
      "/fotos/biolumin_playas_oax/playas_oaxaca_4.jpg",
    ],
    tipo: "Cultural De Temporada Explora México",
  },
  {
    id: 21,
    nombre_tour: "Chiapas Selvatico",
    categoria: '"nacional""_"',
    fechas: ["04/11/2023"],
    destino:
      "Cañón del Sumidero, Chiapa de Corzo, San Cristóbal de las Casas, Comitán, Las Nubes, Las Guacamayas, Selva Lacandona, Bonampak, Yaxchilán, Palenque y Agua Azul.",
    descripcion:
      "Visitando: Cañón del Sumidero, Chiapa de Corzo, San Cristóbal de las Casas, Comitán, Las Nubes, Las Guacamayas, Selva Lacandona, Bonampak, Yaxchilán, Palenque y Agua Azul.",
    incluye:
      "\n\n • Vuelos CDMX -Tuxtla Gutiérrez//Villahermosa- CDMX\n\n • Traslados aeropuerto-hoteles-aeropuerto \n\n • 3 noches de hospedaje 4 * \n\n • 2 noches de hospedaje en Centros Ecoturísticos\n\n • 5 desayunos americanos y/o buffet\n\n • Paseo en lancha por el Cañón del Sumidero\n\n • Paseo en lancha por el Río Lacantún\n\n • Paseo en tranvía en San Cristóbal de las Casas\n\n • Visita a Cascadas del Chiflón\n\n • Acceso a Lagos de Montebello\n\n • Paseo en lancha por el Río Usumacinta\n\n • Acceso a las Cascadas de Agua Azul\n\n • Accesos incluidos (indicados en itinerario)\n\n • Visitas guiadas en zonas arqueológicas\n\n • Coordinador de grupo\n\n • Impuestoso Escondido con desayunos • 2 noches de hospedaje alojamiento en Huatulco Todo Incluido • Tour a Laguna Bioluminiscente • Tour a Puerto Ángel • Coordinador de grupo • Impuestos ",
    imagenes: [
      "/fotos/chiapas_selvatico/chiapas_selvatico_1.jpg",
      "/fotos/chiapas_selvatico/chiapas_selvatico_2.jpg",
      "/fotos/chiapas_selvatico/chiapas_selvatico_3.jpg",
      "/fotos/chiapas_selvatico/chiapas_selvatico_4.jpg",
    ],
    tipo: "Cultural De Aventura Explora México",
  },
  {
    id: 22,
    nombre_tour: "Maravilas del Mundo Maya",
    categoria: '"nacional""_"',
    fechas: ["15/12/2023", "26/12/2023"],
    destino:
      "Mérida, Uxmal, Campeche, Calakmul, Bacalar, Mahahual, Tulum, Playa del Carmen, Chichen  Itza, Cenote Ikkil e Izamal.",
    descripcion:
      "Visitando: Mérida, Uxmal, Campeche, Calakmul, Bacalar, Mahahual, Tulum, Playa del Carmen, Chichen  Itza, Cenote Ikkil e Izamal.",
    incluye:
      "\n\n • Vuelos CDMX-Mérida-CDMX\n\n • Traslados aeropuerto-hoteles-aeropuerto\n\n • 6 noches de hospedaje 4*\n\n • 6 desayunos americanos y/o buffet\n\n • City tours Mérida y Campeche\n\n • Acceso a Zonas arqueológicas de Uxmal, Chichen Itza, Tulum y Calakmul\n\n • Acceso a sitios indicados en itinerario\n\n • Visitas guiadas\n\n • Coordinador de grupo\n\n • Impuestos",
    imagenes: [
      "/fotos/maravillas_mundo_maya/maravillas_mundo_maya_1.jpg",
      "/fotos/maravillas_mundo_maya/maravillas_mundo_maya_2.jpg",
      "/fotos/maravillas_mundo_maya/maravillas_mundo_maya_3.jpg",
      "/fotos/maravillas_mundo_maya/maravillas_mundo_maya_4.jpg",
    ],
    tipo: "Cultural Explora México",
  }, */
  /*  {
    id: 11,
    nombre_tour: "Colombia Multicolor",
    categoria: '"internacional""_"',
    fechas: ["30/08/2023"],
    destino: "COLOMBIA",
    descripcion: "Visitando: Bogotá, Medellín y Cartagena.",
    incluye: "1 Bebidas de Cortesía",
    precio_en_pesos: "$32,960.00",
    imagenes: ["/fotos/inter/colombia.jpeg"],
    tipo: "",
  },
  {
    id: 12,
    nombre_tour: "Maravillas del Perú",
    categoria: '"internacional""_"',
    fechas: ["20/08/2023"],
    destino:
      "PERU,Lima, Cusco, Machu Picchu, Laguna de Humantay, Montaña de 7 colores",
    descripcion:
      "Visitando: Lima, Cusco, Machu Picchu, Laguna de Humantay, Montaña de 7 colores.",
    incluye: "1 Snack de Cortesía",
    precio_en_pesos: "$34,860.00",
    imagenes: ["/fotos/inter/peru.jpeg"],
    tipo: "De Temporada",
  },
  {
    id: 13,
    nombre_tour: "Argentina Maravillosa",
    categoria: '"internacional""_"',
    fechas: ["21/08/2023"],
    destino: "Argentina,Buenos Aires, Bariloche, Iguazú",
    descripcion: "Visitando: Buenos Aires, Bariloche, Iguazú.",
    incluye:
      "Boleto Aéreo México - Buenos Aires- Bariloche - Buenos Aires - Iguazú - México\n\nTraslados aeropuerto - hotel- aeropuerto\n\n03 noches de alojamiento en Buenos Aires con desayunos\n\nCity Tour panorámico por Buenos Aires\n\n03 noches de alojamiento en Bariloche con desayuno\n\nExcursión Circuito Chico y Cerro\n\nCampanario (Con ascenso)\n\n03 noches de alojamiento en Iguazú con desayunos\n\nExcursión Cataratas Argentinas y Brasileras\n\nSeguro de asistencia en viaje AC35\n\nGuía de habla hispana\n\nIncluye 1 maleta documentada de 23 kilos con Latam y 1 maleta de 15 kilos con Aerolíneas Argentinas\n\nSalidas:18 JUN / 20 SEP",
    precio_en_pesos: "$51,884.00",
    imagenes: ["/fotos/inter/argentina.jpeg"],
    tipo: "",
  },
  {
    id: 14,
    nombre_tour: "España Marruecos & Portugal",
    categoria: '"internacional""_"',
    fechas: ["20/08/2023"],
    destino:
      "España,Madrid, Lisboa, Sevilla, Granada, Tanger, Marrakech, Fez, Cadiz, Cordoba, Toledo",
    descripcion:
      "Visitando: Madrid, Lisboa, Sevilla, Granada, Tanger, Marrakech, Fez, Cadiz, Cordoba, Toledo.",
    incluye:
      "Toleto de avión en viaje redondo México –Madrid México en clase turista.\n\n17 noches de hospedaje categoría turista\n\n17 desayunos\n\nTour en barco en Sevilla\n\nTour a Segovia Tour a Sintra, Cascais y Estoril\n\nTour a Toledo\n\nAcceso a catedral de Toledo\n\nAcceso a Mezquita Catedral\n\nRégimen alimenticio conforme a itinerario\n\nGuías de habla hispana\n\nAutocar con aire acondicionado.\n\nTarjeta de asistencia AC35\n\nTraslados indicados\n\nVisitas indicadas\n\nSalida: 30 ABR\n\nPrecio por adulto: $3,590.00 USD Base doble",
    precio_en_pesos: "$62,297.00",
    imagenes: ["/fotos/inter/españa.jpeg"],
    tipo: "",
  },
  {
    id: 15,
    nombre_tour: "Tailandia Mágica",
    categoria: '"internacional""_"',
    fechas: ["23/08/2023"],
    destino: "Tailandia,Bangkok, Ayuthaya, Chiang Rai, Phitsanuloke",
    descripcion: "Visitando: Bangkok, Ayuthaya, Chiang Rai, Phitsanuloke.",
    incluye:
      "Vuelo redondo México – Estambul - Bangkok - Estambul - México, en clase turista\n\n7 Noches de hospedaje Cat. Turista\n\nBoleto de tren Chiang Mai – Bangkok en clase turista\n\nAutocar con aire acondicionado Desayunos diarios\n\nTour de Medio día Templos y Palacio Real\n\nTour Triangulo de Oro\n\nTour al Santuario de Elefantes\n\nAcceso a Mezquita Catedral\n\nTour al pueblo de las Mujeres Jirafas\n\nGuía acompañante de habla hispana\n\nRepresentante de grupo desde CDMX.\n\nTarjeta de asistencia AC35\n\nImpuestos Totales\n\nSalida: 02 Mayo\n\nPrecio por adulto: $2,999.00 USD Base doble\n\n1 / 8",
    precio_en_pesos: "$52,042.00",
    imagenes: ["/fotos/inter/tailandia.jpeg"],
    tipo: "",
  },
  {
    id: 16,
    nombre_tour: "Maravillas de Egipto",
    categoria: '"internacional""_"',
    fechas: ["25/08/2023"],
    destino: "Egipto,El Cairo, Aswan, Kom Ombo, Edfu, Luxor, Piramides de Giza",
    descripcion:
      "Visitando: El Cairo, Aswan, Kom Ombo, Edfu, Luxor, Piramides de Giza.",
    incluye:
      "Vuelo' Inter 'México – El Cairo –México\n\nVuelo domestico Cairo-Luxor/Aswan - Cairo\n\nTours: Joyas de Constantinopla, Bósforo,\n\nIsla griega Chíos, Capadoccia, Abu Dhabi\n\n03 noches de hospedaje en el Cairo\n\n04 noches de pensión completa Crucero (No incluye bebidas)\n\nPirámides: Medio día de visita a las pirámides.\n\nCairo: Dia Completo de Museo Egipcio, Ciudadela y Khan El Khalili con almuerzo.\n\nuxor: Valle de los Reyes, templo de Hatshepsut, Colosos de Memnon, templo de Karnak, templo de Luxor\n\nGuía de habla hispana para las visitas. Traslado conforme a itinerario en auto con aire acondicionado.\n\nEdfu: Templo dedicado a Horus\n\nKom Ombo: Templo dedicado a los dioses Sobek y Haroeris\n\nPaseo en Faluca.\n\nVisa Egipcia\n\nRepresentante desde CDMX\n\nPrecio por adulto: $2,990.00 USD Base doble\n\nSALIDA: 20 de Mayo",
    precio_en_pesos: "$52,042.00",
    imagenes: ["/fotos/inter/egipto.jpeg"],
    tipo: "",
  },
  {
    id: 17,
    nombre_tour: "Egipto D´Lux",
    categoria: '"internacional""_"',
    fechas: ["19/08/2023"],
    destino:
      "Egipto,Estambul, Hurgada, Luxor, Esna, Edfu, Aswan, Kom, Ombo, El Cairo",
    descripcion:
      "Visitando: Estambul, Hurgada, Luxor, Esna, Edfu, Aswan, Kom, Ombo, El Cairo",
    incluye:
      "Vuelo de avión redondo: México – Estambul / El Cairo – México (vía Londres)\n\nIncluye una maleta de 23Kg y 1 de mano de 8kg.\n\nVuelo doméstico Estambul – Hurghada\n\nVuelo doméstico Aswan - El Cairo\n\nIncluyen una maleta de 20Kg y 1 de mano de 7Kg.\n\n1 noches en Estambul\n\n3 noches en Hurgada\n\n4 noches en El Cairo\n\n4 noches en Crucero por el Río Nilo\n\nTraslados hotel – apto – hotel\n\nGuía de habla hispana en todas las visitas\n\nAutocar con aire acondicionado\n\nMaletín de viaje\n\nVisa Egipcia\n\nPropinas Estambul\n\nPropinas Egipto\n\nCity Tour: Museo - Ciudadela - Mezquita de Mahamed Ali - Khan - Comida\n\nValle de los Reyes, Templo de Hastchepsut y Colosos de Nemnon\n\nSalida: 06 OCT\n\nPrecio por adulto: $4,390.00 USD Base doble",
    precio_en_pesos: "$76,180.00",
    imagenes: ["/fotos/inter/egipto.jpeg"],
    tipo: "",
  },
  {
    id: 18,
    nombre_tour: "Maravillas de Japón",
    categoria: '"internacional""_"',
    fechas: ["02/03/2024"],
    destino: "Japon, Narita, Kioto, Tokio",
    descripcion: "Visitando: Narita, Kioto, Tokio.",
    incluye:
      "Boleto de avión en viaje redondo México –Tokio – México, en clase turista.\n\nVuelo domestico Cairo-Luxor/Aswan - Cairo\n\nBoleto de tren bala Nozomi clase turista Tokio – Kioto / Kioto – Tokio.\n\n03 noches de alojamiento en Tokio.\n\n03 noches de alojamiento en Kioto.\n\nVisita a medio día de Kioto\n\nTraslados indicados.\n\nVisitas indicadas.\n\nGuías de habla hispana.\n\nRepresentante desde CDMX\n\nTarjeta de asistencia\n\nutocar con aire acondicionado\n\nSalida: 22 de MAyo\n\nPrecio por adulto: $3,490.00 USD Base doble",
    precio_en_pesos: "$60,562.00",
    imagenes: ["/fotos/inter/japon.jpeg"],
    tipo: "",
  },
  {
    id: 19,
    nombre_tour: "Canadá Espectacular",
    categoria: '"internacional""_"',
    fechas: ["14/08/2023"],
    destino: "Canada,Quebec, Toronto, Ottawa, Niágara, Mil Islas",
    descripcion:
      "Visitando:Espectacular Quebec, Toronto, Ottawa, Niágara, Mil Islas.",
    incluye:
      "Boleto de avión, viaje redondo, clase Turista, México – Toronto // Montreal – México.\n\n07 noches de alojamiento en hoteles categoría turista.\n\nDesayunos Continentales Canadienses descritos en el itinerario.\n\n4 Comidas (1 en Sky Long)\n\nGuía de habla hispana.\n\nVisitas Panorámicas de Ciudad (Toronto, Niagara, Montreal, Quebec, Ottawa).\n\nVisita a Mont Tremblant.\n\nVisita a Cataratas de Niagara\n\nTour en barco de Acercamiento a Cataratas del Niagara\n\nImpuestos. Propinas (mandatarias en destino\n\nTour a Santa Ana de Beaupré\n\nTarjeta de Asistencia Cargo por equipaje (1 equipaje documentado de 23kg)\n\nRepresentante desde CDMX\n\nAcceso a Torre CN\n\nSalida: 13 MAY / 03 JUN / 02 AGO\n\nPrecio por adulto: $2,699.00 USD Base doble",
    precio_en_pesos: "$46,836.00",
    imagenes: ["/fotos/inter/canada.jpeg"],
    tipo: "",
  },
  {
    id: 20,
    nombre_tour: "Sabor a Europa I",
    categoria: '"internacional""_"',
    fechas: ["18/08/2023"],
    destino:
      "Londres, París, Valle del Rhin, Frankfurt, Selva Negra, Zurich, Venecia, Florencia, Roma, Pisa, Niza, Barcelona, Madrid.",
    descripcion:
      "Visitando Londres, París, Valle del Rhin, Frankfurt, Selva Negra, Zurich, Venecia, Florencia, Roma, Pisa, Niza, Barcelona, Madrid.",
    incluye:
      "Vuelo México-Londres//Madrid- México\n\n17 Noches de hospedaje Cat. Turista\n\nAutocar de Lujo\n\nDesayunos diarios\n\nVisitas Panorámicas en Londres - París - Roma - Madrid\n\nVisitas guiadas en Verona, Venecia y Florencia\n\nPaseo en barco en el Sena\n\nAcceso preferencial Museo de Louvre\n\nAcceso preferencial Palacio de Versalles\n\nAccesos preferenciales a Basílica de San Pedro y Museos Vaticanos\n\nTour Roma Barroca con acceso preferencial a Coliseo Romano\n\nTour a Toledo Guía acompañante de habla hispana\n\nRepresentante de grupo desde CDMX\n\nTarjeta de asistencia AC35\n\nImpuestos Totales\n\nSalidas: 09 JUN / 28 JUL / 02 OCT / 18 SEP\n\n$3,990.00 USD Base doble",
    precio_en_pesos: "$69,239.00",
    imagenes: ["/fotos/inter/europa.jpeg"],
    tipo: "",
  },
  {
    id: 21,
    nombre_tour: "Sabor a Europa II",
    categoria: '"internacional""_"',
    fechas: ["19/08/2023"],
    destino:
      "Londres, París, Valle del Rhin, Frankfurt, Selva Negra, Zurich, Lucerna, Venecia, Florencia, Roma, Brujas, Madrid.",
    descripcion:
      "Visitando: Londres, París, Valle del Rhin, Frankfurt, Selva Negra, Zurich, Lucerna, Venecia, Florencia, Roma, Brujas, Madrid.",
    incluye:
      "Vuelo México-Londres//Madrid- México\n\n16 Noches de hospedaje Cat. Turista\n\nAutocar de Lujo\n\nDesayunos diarios Visitas Panorámicas en Londres-París\n\nVisitas guiadas en Venecia, Florencia\n\nAcceso preferencial Museo de Louvre\n\nAcceso preferencial Palacio de Versalles\n\nAccesos preferenciales a Basílica de San Pedro y Museos Vaticanos\n\nExcursión con medios de ascenso a Los Alpes Suizos (Monte Titlis)\n\nRepresentante de grupo desde CDMX\n\nGuía acompañante de habla hispana\n\nPaseo en barco en el Sena\n\nTour Roma Barroca\n\nTour a Toledo\n\nTarjeta de asistencia AC35\n\nImpuestos Totales\n\nSalida: 01 Sep\n\nPrecio por adulto: $3,990.00 USD Base doble",
    precio_en_pesos: "$69,239.00",
    imagenes: ["/fotos/inter/europa.jpeg"],
    tipo: "",
  },
  {
    id: 22,
    nombre_tour: "Joyas de Europa",
    categoria: '"internacional""_"',
    fechas: ["23/08/2023"],
    destino: "Madrid, Burdeos, Blois, París, Londres.",
    descripcion:
      "Visitando: Visitando: Madrid, Burdeos, Blois, París, Londres.",
    incluye:
      "Boleto de avión en viaje redondo México - Madrid // Londres– México en clase turista.\n\n8 noches de hospedaje categoría turista\n\n8 Desayunos\n\nAsenso a la Torre Eiffel (2do piso)\n\nAcceso a Palacio de Versalles\n\nAcceso a Castillo Windsor\n\nTour a Ciudad Imperial de Toledo\n\nTraslados indicados\n\nVisitas indicadas\n\nGuías de habla hispana\n\nAutocar con aire acondicionado.\n\nTarjeta de asistencia AC35\n\nImpuestos aéreos\n\nSalida: 21 JUN / 02 AGO\n\nPrecio por adulto: $2,849.00 USD Base doble",
    precio_en_pesos: "$49,439.00",
    imagenes: ["/fotos/inter/europa.jpeg"],
    tipo: "",
  },
  {
    id: 23,
    nombre_tour: "Tierra Santa Egipto, Petra & Estambul",
    categoria: '"internacional""_"',
    fechas: ["14/08/2023"],
    destino: "Egipto, Petra y Estambul",
    descripcion: "Visitando: Egipto, Petra y Estambul",
    incluye:
      "Vuelo México - Tel Aviv // Estambul - México Vuelos Aqaba-Luxor//Aswan - El Cairo\n\n15 noches de hospedaje\n\n04 noches de crucero por el Nilo 5* con pensión completa (sin bebidas)\n\nTraslados indicados\n\nEspectaculo de luz y sonido en las piramides con cena\n\nTour Mar Muerto y masada\n\nTour Nazareth, Tiberiades y Galilea\n\nTour de día completo en El Cairo (Museo, Ciudadela, Khan) con almuerzo\n\nPropinas mandotorias en destino Impuestos de salida (Jordania e Israel)\n\nTour a templos de Karnak y Luxor\n\nTour a Valle de los Reyes\n\nAutocar con aire acondicionado.\n\nPaseo en Faluca\n\nVisitas indicadas\n\nGuías de habla hispana\n\nTarjeta de asistencia AC35\n\nVisa Turca (nacionales)\n\nVisa de Egipto\n\nSalida: 04 SEP\n\nPrecio por adulto: $4,390.00 USD Base doble",
    precio_en_pesos: "$76,180.00",
    imagenes: ["/fotos/inter/tierra_santa.jpeg"],
    tipo: "",
  },
  {
    id: 24,
    nombre_tour: "Islas Griegas, Dubái & Turquía",
    categoria: '"internacional""_"',
    fechas: ["27/08/2023"],
    destino:
      "Estambul, Ankara, Capadocia, Pamukkale, Kusadasi, Patmos, Creta, Santorini, Atenas, Mykonos, Izmir, Dubái, Abu Dhabi, Sharjah y Ajman.",
    descripcion:
      "Visitando: Estambul, Ankara, Capadocia, Pamukkale, Kusadasi, Patmos, Creta, Santorini, Atenas, Mykonos, Izmir, Dubái, Abu Dhabi, Sharjah y Ajman.",
    incluye:
      "Vuelos directos: México – Estambul – Dubái – Estambul – México.\n\n12 noches de hospedaje categoría primera\n\n4 días 3 noches en Crucero por las Islas Griegas con plan todo incluido (con bebidas alcohólicas incluidas)\n\n15 desayunos (incluidas las del crucero)\n\n6 comidas (incluidas las del crucero)\n\n9 cenas (incluidas las del crucero)\n\nTour por el Bósforo\n\nTour Joyas de Constantinopla\n\nNoche Turca\n\nTour de Creta (Palacio Minoico de Knossos, la primera civilización europea)\n\nTour a Atenas (con acceso a la Acropolis)\n\nTour de Dubai\n\nTour Abu Dhabi\n\nTour en barco en Dhow Cruise\n\nTour en Emiratos Sharjah y Ajman\n\nSafari 4x4 en el desierto\n\nTraslados indicados\n\nVisitas indicadas\n\nGuías de habla hispana\n\nAutocar con aire acondicionado.\n\nTarjeta de asistencia AC35\n\nPropinas en ambos países\n\nImpuesto Turismo Dubái\n\nImpuestos aéreos\n\nSalida: 03 JUN / 28 OCT\n\nPrecio por adulto: $4,390.00 USD Base doble",
    precio_en_pesos: "$76,180.00",
    imagenes: ["/fotos/inter/islas_griegas.jpeg"],
    tipo: "",
  },
  {
    id: 25,
    nombre_tour: "Islas Griegas & Turquía",
    categoria: '"internacional""_"',
    fechas: ["28/08/2023"],
    destino:
      "Estambul, Ankara, Capadocia, Pamukkale, Kusadasi, Patmos, Creta, Santorini, Atenas, Mykonos, Izmir.",
    descripcion:
      "Visitando: Estambul, Ankara, Capadocia, Pamukkale, Kusadasi, Patmos, Creta, Santorini, Atenas, Mykonos, Izmir.",
    incluye:
      "Boleto de avión en viaje redondo México – Estambul – México en clase turista, por British Airways\n\n04 noches de alojamiento en Estambul con desayunos.\n\n03 noches de crucero por las Islas Griegas con pensión completa\n\n01 noche de alojamiento en Kusadasi con desayuno\n\n01 noche de alojamiento en Pamukkale con desayuno\n\n02 noche de alojamiento en Capadocia con desayuno\n\n11 desayunos\n\n2 comidas\n\n7 cenas\n\nAcceso y recorrido en Éfeso\n\nTour por el Bósforo de Euro Asia\n\nTour Joyas de Constantinopla\n\nTour de Creta\n\nExcursión incluida al: Palacio Minoico de Knossos, la primera civilización europea.\n\nTour panorámico en Atenas\n\nNoche Turca\n\nImpuestos portuarios\n\nTramite de visa turca (Nacionales)\n\nTraslados indicados.\n\nVisitas indicadas.\n\nGuías de habla hispana.\n\nAutocar con aire acondicionado\n\nSalida: 05 MAY\n\nPrecio por adulto: $3,599.00 USD Base doble",
    imagenes: ["/fotos/inter/islas_griegas.jpeg"],
    tipo: "",
  }, */
];
export const Tours_en = [
  {
    id: 1,
    nombre_tour: "BALLOON FLIGHT AND GUIDED TOUR IN TEOTIHUACAN",
    categoria: '"Nacional""_"',
    fechas: ["02/03/2024"],
    destino: '"CDMX"',
    descripcion:
      "It's a unique experience. We'll pick you up from your accommodation to take you to the Balloonport where you can enjoy a Coffee Break while watching the assembly and inflation of the balloon you'll be boarding.\n The flight will last 45 to 60 minutes, during which you can contemplate the sunrise, the majestic city of the gods, and different balloons that will accompany you during your experience.\n  Upon landing, you'll enjoy a toast with sparkling wine and breakfast at a local restaurant to recharge your energy and continue our day with a certified guide during a 1 to 1.5-hour walk in the Teotihuacan Archaeological Zone.",
    requisitos: "_",
    incluye:
      "-Round-trip transportation from your accommodation (ask about our pick-up zones)\n-Certified guide\n-Complimentary beverages\n-Breakfast\n-Entrance tickets and guided tour in Teotihuacan",
    precio_en_pesos: "$3,900.00 MXN\nper Adult",
    imagenes: [
      "/fotos/teo_con_globo/tg_1.jpg",
      "/fotos/teo_con_globo/tg_2.jpg",
      "/fotos/teo_con_globo/tg_3.jpg",
      "/fotos/teo_con_globo/tg_4.jpg",
    ],
    tipo: "Cultural",
  },
  {
    id: 2,
    nombre_tour: "BALLOON FLIGHT AND TEOTIHUACAN VISIT",
    categoria: '"Nacional""_"',
    fechas: ["02/03/2024"],
    destino: '"CDMX"',
    descripcion:
      "It's a unique experience. We'll pick you up from your accommodation to take you to the Balloonport where you can enjoy a Coffee Break while watching the assembly and inflation of the balloon you'll be boarding.\nThe flight will last 45 to 60 minutes, during which you can contemplate the sunrise, the majestic city of the gods, and different balloons that will accompany you during your experience.\nUpon landing, you'll enjoy a toast with sparkling wine and breakfast at a local restaurant to recharge your energy and continue with the visit of two hours at Teotihuacan Archaeological Zone",
    requisitos: "_",
    incluye:
      "-Round-trip transportation from your accommodation (ask about our pick-up zones)\n-ertified guide\n-Complimentary beverages\n-Breakfast\n-Entrance to the Teotihuacan Archaeological Zone",
    precio_en_pesos: "$3,500.00 MXN\nper Adult",
    imagenes: [
      "/fotos/teo_con_globo/tg_7.jpg",
      "/fotos/teo_con_globo/tg_2.jpg",
      "/fotos/teo_con_globo/tg_3.jpg",
      "/fotos/teo_con_globo/tg_4.jpg",
    ],
    tipo: "Cultural",
  },

  {
    id: 3,
    nombre_tour: "TEOTIHUACAN Private Guided Tour",
    categoria: '"nacional""_"',
    fechas: ["30/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "This visit is led by one of our guides who will pick you up from your accommodation (ask about our pick-up zones).\nYou will delve into the history of this great place, over two thousand years old. We will visit the palaces of the elite in Teotihuacan, then move on to the Plaza and Pyramid of the Moon. At the Plaza of the Moon, you'll experience the acoustics of the place before heading towards the Avenue of the Dead, where we can observe mural paintings, ending our tour in front of the Pyramid of the Sun.",
    requisitos: "_",
    incluye:
      "-Round-trip private transportation (ask about our pick-up zones)\n-Certified guide\n-Complimentary beverages\n-Entrance and guided tour in the archaeological zone",
    precio_en_pesos: "$2,200 MXN\n per Adult",
    imagenes: [
      "/fotos/teo/t_basil_1.jpg",
      "/fotos/teo/t_2.jpg",
      "/fotos/teo/t_3.jpg",
      "/fotos/teo/t_4.jpg",
    ],
    tipo: "Cultural",
  },

  {
    id: 4,
    nombre_tour: "XOCHIMILCO/ COYOACAN / FRIDA KAHLO",
    categoria: '"nacional""_"',
    fechas: [["28/01/2024"], ["03/02/2024"]],
    destino: '"CDMX"',
    descripcion:
      "Live an unforgettable experience, full of folklore, culture, beauty and magic; these are the ingredients that make up this wonderful tour of the main attractions of Mexico City!\nOur tour begins heading to the channels of Xochimilco, a cultural heritage of humanity. Enjoy a relaxing and joyful tour of the canals of Xochimilco aboard a colorful wooden boat called “Trajinera”.\nYou can buy local drinks and regional snacks on one of the many restaurants boats that travel through the canals.\nEnjoy the regional mariachi music, which livens up and lights up the atmosphere of the place. We will continue the tour to the Coyoacán neighborhood, a place full of culture and tradition, the favorite neighborhoods of great Mexican artists and writers; Walk through its cobblestone streets lined with trees, admire the stucco and tile facades of its beautiful colonial houses and soak up its history.\nLater, we will go to the Frida Kahlo Museum, which is the former home of the famous Mexican painter. You will be able to learn about the life of this iconic figure and her famous husband, Diego Rivera. Likewise, you can appreciate the picturesque garden and walk through it.",
    incluye:
      "Round transportation (ask about our pick up zones)\n-1 hour trajinera ride\n-2 Complimentary drinks\n-Entrance fees to different visiting points\n-Courtesy snack\n-Start of the Tour: 8:00 hrs Approx.",

    requisitos:
      "*This tour must be booked at least 2 weeks in advance,\n two people minimum",
    precio_en_pesos: "$2,900.00 MXN\nper Adult",
    imagenes: [
      "/fotos/xochi_coyo_frida/x1.jpg",
      "/fotos/xochi_coyo_frida/x2.jpg",
      "/fotos/xochi_coyo_frida/x3.jpg",
      "/fotos/xochi_coyo_frida/x4.jpg",
    ],
    tipo: "Cultural",
  },

  {
    id: 5,
    nombre_tour: "TOUR FRIDA KAHLO Y DIEGO RIVERA",
    categoria: '"nacional""_"',
    fechas: ["02/03/2024"],
    destino: '"CDMX"',
    descripcion:
      "Embark on a journey to explore the vibrant life works of two legendary Mexican artists: Frida Kahlo and Diego Rivera.On this private tour, you'll get an intimate look at their greatest masterpieces while visiting the Museo Casa Estudio Diego Rivera and Frida Kahlo, the Anahuacalli Museum and the famous blue walls of Kahlo's house in which most of her best-known were created.",
    requisitos:
      "*This tour must be booked at least 2 weeks in advance,\ntwo people minimum",
    incluye:
      "-Round transportation(ask about our pick up zones)\n-Certified guide\n-Entrance fees to the different visiting points\nComplimentary snack\n-2 Complimentary drinks",
    precio_en_pesos: "$2,500.00 MXN\n per Adult",
    imagenes: [
      "/fotos/frida_diego/f_1.jpg",
      "/fotos/frida_diego/f_2.jpg",
      "/fotos/frida_diego/f_3.jpg",
      "/fotos/frida_diego/f_4.jpg",
    ],
    tipo: "Cultural",
  },
  {
    id: 6,
    nombre_tour: "KAYAKING AT DAWN",
    categoria: '"nacional""_"',
    fechas: ["31/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "This Xochimilco Kayak tour is an immersive and unique experience that will take you on a new adventure through the surreal town of Xochimilco. Where the endemic flora and fauna are witnesses of a fascinating tour that delights the senses in a completely natural environment.\nWe will visit the main chinampas of the place, such as the Island of the Dolls, the Tlilac Lagoon, and we will also get to know the Axolotl or Ajolote, an endemic salamander in danger of extinction considered a deity by the Aztecs due to its capacity for cell regeneration.\nThroughout the tour we will observe local and migratory birds that relax the soul, and at the end we will enjoy a breakfast prepared with local ingredients.",
    requisitos: "_",
    incluye:
      "-Round transport (Ask about our pick up zones)\n-Certified guide\n-Kayak per person\n-Kayak training\n-Complimentary drinks\n-Breakfast\n-Entrance fees to different visiting points",
    precio_en_pesos: "$1,950.00 MXN\nper Adult",
    imagenes: [
      "/fotos/kayak/k_1.jpg",
      "/fotos/kayak/k_2.jpg",
      "/fotos/kayak/k_3.jpg",
      "/fotos/kayak/k_4.jpg",
    ],
    tipo: "De Aventura",
  },
  {
    id: 7,
    nombre_tour: "Tolantongo´s Hot Springs Private",
    categoria: '"nacional""_"',
    fechas: ["31/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "Join us for an exciting adventure at the Grutas de Tolantongo with our special tour.\n We'll take care of your transportation to and from your location, ensuring comfort and safety at all times. During the journey, our guide will share the secrets of authentic barbacoa, a traditional dish that will delight your palate with its exquisite flavor.\n Additionally, you'll have the opportunity to try pulque, a typical beverage that will complement your gastronomic experience.\n Upon arrival at the Grutas, you'll be amazed by its natural beauty as you explore its impressive caves and thermal pools. To refresh yourself, we'll provide you with two complimentary beverages, so you can fully enjoy this day filled with adventure and discoveries.",
    requisitos: "*Minimum booking time: 1 week\n*Minimum of 2 people",
    incluye:
      "-Round-trip transportation  (ask about our pick-up zones)\n-Certified guide\n-2 complimentary beverages\n-Pulque tasting\n-Entrance fees to various points of interest",
    precio_en_pesos: "$2,800.00 MXN\n per Adult",
    imagenes: [
      "/fotos/tolantongo/tola_1.jpg",
      "/fotos/tolantongo/tola_2.jpg",
      "/fotos/tolantongo/tola_3.jpg",
      "/fotos/tolantongo/tola_4.jpg",
    ],
    tipo: "De Aventura",
  },

  {
    id: 8,
    nombre_tour: "HISTORIC CENTER Walking Private Tour",
    categoria: '"nacional""_"',
    fechas: ["31/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "We will travel through time in this beautiful city, from pre-Hispanic times to colonial times, and finally to the Modern City.\n We will visit the Pyramid of the Templo Mayor in Tenochtitlan, a pre-Hispanic city, as well as the Metropolitan Cathedral and contemplate its eclectic architectural style. The tour continues on Francisco I. Madero Street, where we will appreciate colonial and modern architecture such as the House of Tiles, the Latin American Tower, the National Art Museum, the Mining Palace, and the Post Office.\nFinally, we will admire the Palace of Fine Arts, its architecture, and history.\nWithin all these monuments, there are great stories, anecdotes, and interesting legends to tell that we will greatly enjoy.",
    requisitos: "_",
    incluye: "-Certified guide\n-Sun umbrella\n-2 Complimentary beverages",
    precio_en_pesos: "$1,100 MXN\n per Adult",
    imagenes: [
      "/fotos/centro_historico/ch_1.jpg",
      "/fotos/centro_historico/ch_2.jpg",
      "/fotos/centro_historico/ch_3.jpg",
      "/fotos/centro_historico/ch_4.jpg",
    ],
    tipo: "Cultural",
  },
  {
    id: 9,
    nombre_tour: "CHAPULTEPEC CASTLE AND ANTHROPOLOGY MUSEUM",
    categoria: '"nacional""_"',
    fechas: ["31/01/2024"],
    destino: '"CDMX"',
    descripcion:
      "Embark on an early morning excursion to discover the fascinating history of Mexico from the days of the Aztecs to the present, learn about Spanish colonialism and Mexico's independence while marveling at the royal chambers and important artworks of Chapultepec Castle.\n Continue to the nearby National Museum of Anthropology to see its most significant artifacts, such as the carved heads of the Olmecs, the tomb of Pakal, and the Sun Stone.",
    requisitos: "_",
    incluye:
      "-Certified guide\n-Sun umbrellas\n-1 Complimentary beverage\n-1 Complimentary snack\n-Entrance tickets and guided tour to both museums",
    precio_en_pesos: "$1,100 MXN\n per Person",
    imagenes: [
      "/fotos/castillo_chapultepec/castillo_chap_1.jpg",
      "/fotos/castillo_chapultepec/castillo_chap_2.jpg",
      "/fotos/castillo_chapultepec/castillo_chap_3.jpg",
      "/fotos/castillo_chapultepec/castillo_chap_4.jpg",
    ],
    tipo: "Cultural",
  },
];
