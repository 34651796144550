import React, { useEffect, useRef } from "react";
import { Tours, Tours_en } from "./tours";
import PropTypes, { number } from "prop-types";
import Inicio from "./Inicio";
import { useParams } from "react-router-dom";
import Carousel from "better-react-carousel";
import ReactWhatsapp from "react-whatsapp";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useTranslation } from "react-i18next";

let imagenes_tours_1 = "";
let imagenes_tours_2 = "";
let imagenes_tours_3 = "";
let imagenes_tours_4 = "";

const MyDot = ({ isActive }) => (
  <span
    style={{
      display: "inline-block",
      height: isActive ? "8px" : "5px",
      width: isActive ? "8px" : "5px",
      background: "#1890ff",
    }}
  ></span>
);

const Gallery = () => {
  return (
    <Carousel cols={1} rows={1} loop dot={MyDot}>
      <Carousel.Item>
        <center>
          <img className="img_carousel" src={imagenes_tours_1} />
        </center>
      </Carousel.Item>
      <Carousel.Item>
        <center>
          <img className="img_carousel" src={imagenes_tours_2} />
        </center>
      </Carousel.Item>
      <Carousel.Item>
        <center>
          {" "}
          <img className="img_carousel" src={imagenes_tours_3} />
        </center>
      </Carousel.Item>
      <Carousel.Item>
        <center>
          {" "}
          <img className="img_carousel" src={imagenes_tours_4} />
        </center>
      </Carousel.Item>
      {/*   <Carousel.Item>
        <img height="500px" src={imagenes_tours_5} />
      </Carousel.Item> */}
      {/* ... */}
    </Carousel>
  );
};
const Detalles_tours = () => {
  const [t, i18n] = useTranslation("global");

  let idioma = t("language.selected");
  const scrollToElementRef = useRef(null);

  useEffect(() => {
    if (scrollToElementRef.current) {
      scrollToElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();
  /*   {
    Tours.filter((tour) => tour.id == tourId).map((tour) => (
      <h1>{tour.descripcion}</h1>
    ));
  } */

  if (idioma === "espanol") {
    return (
      <div>
        {/*   <h1>Id Tour = {params.id}</h1> */}

        {Tours.filter((tour) => tour.id == params.id).map((tour) => (
          <div className="contenedor_detalles" key={tour.id}>
            <div
              className="contenedor_nombre_tour"
              id="targetElement"
              ref={scrollToElementRef}
            >
              <h2 className="nombre_tour" id="section1">
                {tour.nombre_tour}
              </h2>
            </div>

            <div className="contenedor_imagenes_con_precio">
              <div className="grid_columnas_detalles_tours">
                <div className="contenedor_general">
                  <p className="oculto_img">
                    {(imagenes_tours_1 = tour.imagenes[0])}
                  </p>
                  <p className="oculto_img">
                    {(imagenes_tours_2 = tour.imagenes[1])}
                  </p>
                  <p className="oculto_img">
                    {(imagenes_tours_3 = tour.imagenes[2])}
                  </p>
                  <p className="oculto_img">
                    {(imagenes_tours_4 = tour.imagenes[3])}
                  </p>
                  <div className="marco_galeria_imagenes">
                    <Gallery />
                  </div>
                </div>
              </div>

              <center>
                <div className="contenedor_descripcion">
                  <div className="fondo_blanco_detalles">
                    <p>{tour.descripcion}</p>
                    <p>
                      <strong>{tour.requisitos}</strong>
                    </p>
                    <h3>{t("see.included")}:</h3>
                    <p>{tour.incluye}</p>
                    {/* <div className="formato_fechas">
                      <h3>Fechas</h3>
                      <p>{tour.fechas}</p>
                    </div> */}

                    <div className="fondo_blanco_precio">
                      <h2>{tour.precio_en_pesos} </h2>
                      <h3>por persona</h3>
                      {/* <button className="boton_reservar"> Reservar Tour </button> */}
                      {/* componente boton flotente de whatsapp */}
                      {/*  <FloatingWhatsApp chatMessage="Hola, estoy para servirte" /> */}{" "}
                    </div>

                    <div className="boton_whatsapp">
                      <ReactWhatsapp
                        className="boton_whatsapp"
                        class="btn btn-primary"
                        number="525580323918"
                        message="Hola, me interesa reservar"
                      >
                        Informes y Reservaciones vía WhatsApp{" "}
                      </ReactWhatsapp>
                    </div>

                    {/*  <div className="boton_whatsapp">
                      <p>
                        Para información y reservaciones tenemos a su disposición
                        el siguiente número de
                      </p>
                      <p>Whatsapp: 52 55 8032 3918</p>
                    </div> */}
                  </div>
                </div>
              </center>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div>
        {/*   <h1>Id Tour = {params.id}</h1> */}

        {Tours_en.filter((tour) => tour.id == params.id).map((tour) => (
          <div className="contenedor_detalles" key={tour.id}>
            <div
              className="contenedor_nombre_tour"
              id="targetElement"
              ref={scrollToElementRef}
            >
              <h2 className="nombre_tour" id="section1">
                {tour.nombre_tour}
              </h2>
            </div>

            <div className="contenedor_imagenes_con_precio">
              <div className="grid_columnas_detalles_tours">
                <div className="contenedor_general">
                  <p className="oculto_img">
                    {(imagenes_tours_1 = tour.imagenes[0])}
                  </p>
                  <p className="oculto_img">
                    {(imagenes_tours_2 = tour.imagenes[1])}
                  </p>
                  <p className="oculto_img">
                    {(imagenes_tours_3 = tour.imagenes[2])}
                  </p>
                  <p className="oculto_img">
                    {(imagenes_tours_4 = tour.imagenes[3])}
                  </p>
                  <div className="marco_galeria_imagenes">
                    <Gallery />
                  </div>
                </div>
              </div>

              <center>
                <div className="contenedor_descripcion">
                  <div className="fondo_blanco_detalles">
                    <p>{tour.descripcion}</p>
                    <p>
                      <strong>{tour.requisitos}</strong>
                    </p>
                    <h3>{t("see.included")}</h3>
                    <p>{tour.incluye}</p>
                    {/* <div className="formato_fechas">
                      <h3>Fechas</h3>
                      <p>{tour.fechas}</p>
                    </div> */}

                    <div className="fondo_blanco_precio">
                      <h2>{tour.precio_en_pesos} </h2>
                      {/* <button className="boton_reservar"> Reservar Tour </button> */}
                      {/* componente boton flotente de whatsapp */}
                      {/*  <FloatingWhatsApp chatMessage="Hola, estoy para servirte" /> */}{" "}
                    </div>

                    <div className="boton_whatsapp">
                      <ReactWhatsapp
                        className="boton_whatsapp"
                        class="btn btn-primary"
                        number="525580323918"
                        message="Hola, me interesa reservar"
                      >
                        {t("see.whatsapp_message")}
                      </ReactWhatsapp>
                    </div>

                    {/*  <div className="boton_whatsapp">
                      <p>
                        Para información y reservaciones tenemos a su disposición
                        el siguiente número de
                      </p>
                      <p>Whatsapp: 52 55 8032 3918</p>
                    </div> */}
                  </div>
                </div>
              </center>
            </div>
          </div>
        ))}
      </div>
    );
  }
};

export default Detalles_tours;
