import "./App.css";
import Login from "./Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from "./Signup";
import Home from "./Home";
import Barra_Nav from "./Barra_Nav";
import Inicio from "./Inicio";
import Inicio_en from "./Inicio_en";
import Detalles_Tours from "./Detalles_Tours";
import Layout from "./Layout";
import BusquedaTipoTour from "./BusquedaTipoTour";
import { Info_Bonviajes } from "./Info_Bonviajes";
import ScrollToTop from "./component/ScrollToTop";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "Bonviajes";
  });
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route> */}
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<BusquedaTipoTour />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/inicio" element={<Inicio />}></Route>
          <Route path="/index/en" element={<Inicio_en />}></Route>
          <Route
            path="/BusquedaTipoTour"
            element={<BusquedaTipoTour />}
          ></Route>

          <Route path="/info_bonviajes" element={<Info_Bonviajes />}></Route>
          <Route
            path="/detalles_tours/:id"
            element={<Detalles_Tours />}
          ></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
