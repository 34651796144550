import { Outlet, Link } from "react-router-dom";
//import Quienes_somos from "./Quienes_somos";
//import Info_tours from "./Info_tours";
//import Inicio from "../pages/Inicio";
import BusquedaTipoTour from "./BusquedaTipoTour";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Layout = () => {
  const [t, i18n] = useTranslation("global");
  const handleClick = () => navigate("/");
  const navigate = useNavigate();
  const handleLogout = () => {
    axios
      .get("http://localhost:8081/logout")
      .then((res) => {
        if (res.data.Status === "Success") {
          window.location.reload(true);
        } else {
          alert("Error");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="contenedor_menu">
        <header className="contenedor_encabezado">
          <div className="encabezado">
            <div className="contenedor_info_contacto">
              <h2 className="info_contacto"> </h2>
              <div className="logos_contacto">
                <img
                  className="iconos_info_contacto"
                  src="../fotos/branding/whatsapp_small.gif"
                  alt="logo whatsapp"
                ></img>
                <h2 className="info_contacto">52 55 8032 3918</h2>
              </div>
              <div className="logos_contacto">
                <img
                  className="iconos_info_contacto"
                  src="../fotos/branding/email_small.gif"
                  alt="logo correo"
                ></img>
                <h2 className="info_contacto">
                  bonviajesexperiencias@gmail.com
                </h2>
              </div>
              <a
                href="https://www.instagram.com/bonviajes?igsh=eGtrNDA5bWxubWdt"
                target="_blank"
                rel="noreferrer"
              >
                <div className="logos_contacto">
                  <img
                    className="iconos_info_contacto"
                    src="../fotos/branding/instagram_small.gif"
                    alt="logo instagram"
                  ></img>
                  <h2 className="info_contacto">@bonviajes</h2>
                </div>
              </a>
            </div>
            <a
              href="https://www.tiktok.com/@bonviajestravel?_t=8lwb9GyCdLp&_r=1"
              target="_blank"
              rel="noreferrer"
            >
              <div className="logos_contacto">
                <img
                  className="iconos_info_contacto"
                  src="../fotos/branding/logo_tiktok.gif"
                  alt="logo tiktok"
                ></img>
                <h2 className="info_contacto">@bonviajestravel</h2>
              </div>
            </a>
            <div className="botones_idioma">
              <p>Seleccionar Idioma/Select Language</p>
              <button
                className="botones_idioma"
                onClick={() => i18n.changeLanguage("es")}
              >
                <img
                  src="../fotos/branding/bandera_mexico.jpg"
                  alt="idioma espanol"
                ></img>
              </button>

              <button
                className="botones_idioma"
                onClick={() => i18n.changeLanguage("en")}
              >
                <img
                  src="../fotos/branding/bandera_usa.jpg"
                  alt="logo whatsapp"
                ></img>
              </button>
            </div>
            <center>
              <div className="contenedor_logo_bv">
                <img
                  className="logo_bv"
                  src="../fotos/branding/logo_bonviajes.png"
                  alt="idioma ingles"
                />
              </div>
            </center>

            <center>
              <nav className="menu">
                <ul className="lista">
                  {/*  <li className="item_menu">
                      <Link to="/home">Inicio</Link>
                    </li> */}

                  {/*   <li className="item_menu">
                      <Link to="/Quienes_somos">Quienes Somos</Link>
                    </li> */}
                  <li className="item_menu">
                    <Link to="/BusquedaTipoTour">{t("header.our-tours")}</Link>
                  </li>

                  {/*   <li className="item_menu">
                      <Link to="/Inicio">Busqueda</Link>
                    </li> */}

                  <li className="item_menu">
                    <Link to="/Info_Bonviajes">{t("header.info-page")}</Link>
                  </li>

                  {/*  <li className="item_menu">
                      <Link to="/Detalles_tours">Detalles</Link> 
                    </li>*/}
                </ul>
              </nav>
            </center>
          </div>

          {/*   <div style={{ float: "right" }}>
            <button className="boton_salir" type="button" onClick={handleClick}>
              Salir
            </button>
          </div> */}
        </header>

        <Outlet className="fondo" />
      </div>
    </div>
  );
};

export default Layout;
