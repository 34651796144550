import React from "react";
import { useState } from "react";
import { Tours, Tours_en } from "./tours";

import BtnLike from "./component/BtnLike";
import { StarIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import Inicio from "./Inicio";
import { useTranslation } from "react-i18next";

function BusquedaTipoTour() {
  const [t, i18n] = useTranslation("global");

  let idioma = t("language.selected");

  let id = 0;
  let dirTour = "";
  const [selectedValue, setSelectedValue] = useState("Cultural");

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  const styles = {
    container: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    heading: {
      color: "#F9629F",
      textAlign: "center",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: "20px",
      borderRadius: "8px",
      backgroundColor: "white",
      padding: "5px",
      boxShadow: "0px 2px 3.84px rgba(0, 0, 0, 0.25)",
    },
    radioButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "10px",
    },

    radioLabel: {
      marginLeft: "8px",
      color: "rgb(207, 26, 111)",
      fontSize: "15px",
      cursor: "pointer",
      fontWeight: "500",
    },
    centerLabel: {
      textAlign: "center",
    },
  };
  if (idioma === "espanol") {
    return (
      <div className="contenedor_ambas_busquedas">
        <center>
          {" "}
          <Inicio />
        </center>
        <center>
          <div
            className="contenedor_categoria_tours" /*style={styles.container*/
          >
            <h1 style={styles.heading}>{t("search.find-tour-type")}</h1>

            <div className="contenedor_selecciones">
              <radioGroup className="radiogroup_selecciones">
                <div className="input_radio_selecciones">
                  <input
                    type="radio"
                    id="option1"
                    value="Cultural"
                    checked={selectedValue === "Cultural"}
                    onChange={() => handleRadioChange("Cultural")}
                  />
                  <label htmlFor="option1" style={styles.radioLabel}>
                    {t("categories.cultural")}
                  </label>
                </div>

                <div
                  className="input_radio_selecciones" /*  style={styles.radioButton} */
                >
                  <input
                    type="radio"
                    id="option2"
                    value="De Aventura"
                    checked={selectedValue === "De Aventura"}
                    onChange={() => handleRadioChange("De Aventura")}
                  />
                  <label htmlFor="option2" style={styles.radioLabel}>
                    {t("categories.adventure")}
                  </label>
                </div>

                <div
                  className="input_radio_selecciones" /*  style={styles.radioButton} */
                >
                  <input
                    type="radio"
                    id="option3"
                    value="De Temporada"
                    checked={selectedValue === "De Temporada"}
                    onChange={() => handleRadioChange("De Temporada")}
                  />
                  <label htmlFor="option3" style={styles.radioLabel}>
                    {t("categories.season")}
                  </label>
                </div>

                {/* <div
              className="input_radio_selecciones" 
            >
              <input
                type="radio"
                id="option4"
                value="Religioso"
                checked={selectedValue === "Religioso"}
                onChange={() => handleRadioChange("Religioso")}
              />
              <label htmlFor="option4" style={styles.radioLabel}>
                Religioso
              </label>
            </div> */}

                <div
                  className="input_radio_selecciones" /* style={styles.radioButton} */
                >
                  <input
                    type="radio"
                    id="option5"
                    value="Explora México"
                    checked={selectedValue === "Explora México"}
                    onChange={() => handleRadioChange("Explora México")}
                  />
                  <label htmlFor="option5" style={styles.radioLabel}>
                    {t("categories.explore-mexico")}
                  </label>
                </div>
              </radioGroup>
            </div>

            {/*  <div style={styles.radioButton}>
            <input
              type="radio"
              id="option6"
              value="Explora México"
              checked={selectedValue === "Playas"}
              onChange={() => handleRadioChange("Playas")}
            />
            <label htmlFor="option6" style={styles.radioLabel}>
              Playas
            </label>
          </div> */}
          </div>
        </center>
        <h1 style={styles.centerLabel}>{selectedValue}</h1>
        {
          <div className="formato_pagina">
            {Tours.filter((tour) => tour.tipo.includes(selectedValue)).map(
              (tour) => (
                <div className="contenedor_general">
                  <img
                    src={tour.imagenes[0]}
                    alt={tour.nombre_tour}
                    className="imagenes"
                  />

                  <div className="contenedor_info">
                    <div className="contenedor_formato">
                      <h3 className="titulo_nombre_tour">{tour.nombre_tour}</h3>
                      <div className="contenedor_estrella">
                        <BtnLike />
                        <StarIcon className="star_icon" />
                      </div>
                    </div>

                    {/*<p className="text-gray-600 mb-4">{tour.descripcion}</p>*/}

                    <h4 className="titulo_nombre_tour">{tour.destino}</h4>
                    <p className="oculto">
                      {(id = tour.id)}

                      {(dirTour = "/Detalles_tours/" + id)}
                    </p>

                    <Link to={dirTour}></Link>
                    {/*<span className="text-gray-800">{listing.precio_en_pesos}</span> */}

                    {/*  <h3>{tour.descripcion}</h3>
          <h3>{tour.fechas}</h3>
          <h2>{tour.precio_en_pesos}</h2> */}
                  </div>
                </div>
              )
            )}
          </div>
        }{" "}
        else {}
      </div>
    );
  } else {
    return (
      <div className="contenedor_ambas_busquedas">
        <center>
          {" "}
          <Inicio />
        </center>
        <center>
          <div
            className="contenedor_categoria_tours" /*style={styles.container*/
          >
            <h1 style={styles.heading}>{t("search.find-tour-type")}</h1>

            <div className="contenedor_selecciones">
              <radioGroup className="radiogroup_selecciones">
                <div className="input_radio_selecciones">
                  <input
                    type="radio"
                    id="option1"
                    value="Cultural"
                    checked={selectedValue === "Cultural"}
                    onChange={() => handleRadioChange("Cultural")}
                  />
                  <label htmlFor="option1" style={styles.radioLabel}>
                    {t("categories.cultural")}
                  </label>
                </div>

                <div
                  className="input_radio_selecciones" /*  style={styles.radioButton} */
                >
                  <input
                    type="radio"
                    id="option2"
                    value="De Aventura"
                    checked={selectedValue === "De Aventura"}
                    onChange={() => handleRadioChange("De Aventura")}
                  />
                  <label htmlFor="option2" style={styles.radioLabel}>
                    {t("categories.adventure")}
                  </label>
                </div>

                <div
                  className="input_radio_selecciones" /*  style={styles.radioButton} */
                >
                  <input
                    type="radio"
                    id="option3"
                    value="De Temporada"
                    checked={selectedValue === "De Temporada"}
                    onChange={() => handleRadioChange("De Temporada")}
                  />
                  <label htmlFor="option3" style={styles.radioLabel}>
                    {t("categories.season")}
                  </label>
                </div>

                {/* <div
              className="input_radio_selecciones" 
            >
              <input
                type="radio"
                id="option4"
                value="Religioso"
                checked={selectedValue === "Religioso"}
                onChange={() => handleRadioChange("Religioso")}
              />
              <label htmlFor="option4" style={styles.radioLabel}>
                Religioso
              </label>
            </div> */}

                <div
                  className="input_radio_selecciones" /* style={styles.radioButton} */
                >
                  <input
                    type="radio"
                    id="option5"
                    value="Explora México"
                    checked={selectedValue === "Explora México"}
                    onChange={() => handleRadioChange("Explora México")}
                  />
                  <label htmlFor="option5" style={styles.radioLabel}>
                    {t("categories.explore-mexico")}
                  </label>
                </div>
              </radioGroup>
            </div>

            {/*  <div style={styles.radioButton}>
            <input
              type="radio"
              id="option6"
              value="Explora México"
              checked={selectedValue === "Playas"}
              onChange={() => handleRadioChange("Playas")}
            />
            <label htmlFor="option6" style={styles.radioLabel}>
              Playas
            </label>
          </div> */}
          </div>
        </center>
        <h1 style={styles.centerLabel}>{selectedValue}</h1>
        {
          <div className="formato_pagina">
            {Tours_en.filter((tour) => tour.tipo.includes(selectedValue)).map(
              (tour) => (
                <div className="contenedor_general">
                  <p className="oculto">
                    {(id = tour.id)}

                    {(dirTour = "/Detalles_tours/" + id)}
                  </p>
                  <Link to={dirTour}>
                    <img
                      src={tour.imagenes[0]}
                      alt={tour.nombre_tour}
                      className="imagenes"
                    />{" "}
                  </Link>

                  <div className="contenedor_info">
                    <div className="contenedor_formato">
                      <h3 className="titulo_nombre_tour">{tour.nombre_tour}</h3>
                      <div className="contenedor_estrella">
                        <BtnLike />
                        <StarIcon className="star_icon" />
                      </div>
                    </div>

                    {/*<p className="text-gray-600 mb-4">{tour.descripcion}</p>*/}

                    <h4 className="titulo_nombre_tour">{tour.destino}</h4>

                    {/*<span className="text-gray-800">{listing.precio_en_pesos}</span> */}

                    {/*  <h3>{tour.descripcion}</h3>
          <h3>{tour.fechas}</h3>
          <h2>{tour.precio_en_pesos}</h2> */}
                  </div>
                </div>
              )
            )}
          </div>
        }{" "}
        else {}
      </div>
    );
  }
}
export default BusquedaTipoTour;
